@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Aller Display";
  src: url("./fonts/AllerDisplay.eot?") format("eot"),
    url("./fonts/AllerDisplay.woff") format("woff"),
    url("./fonts/AllerDisplay.ttf") format("truetype"),
    url("./fonts/AllerDisplay.svg#AllerDisplay") format("svg");
  font-weight: normal;
  font-style: normal;
}

.map-container{
  width: 100%;
  height: 700px;
}

/* .leaflet-container .leaflet-pane .leaflet-tile-container {
  width: 100%;
  height: 300px !important;
} */

#map {
  height: 300px;
  width: 100%;
}

.leaflet-container {
  width: 100%;
  height: 500px !important;
}

html,
body {
  font-family: "Poppins";
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

/** {
  cursor: none;
}*/

input[type="number"]
{
  -moz-appearance:textfield !important;
    -webkit-appearance: textfield !important;
    margin: 0;
}

@layer components {
  .p-content {
    @apply px-4 md:px-20 lg:px-40 xl:px-60;
  }

  .p-content-narrow {
    @apply px-4 md:px-52 lg:px-72 xl:px-96;
  }

  .tooltip {
    @apply invisible absolute;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-30;
  }
}

input:checked + svg {
  display: block;
}

.pac-target-input {
  height: 3rem;
  background-color: #fff;
  border-radius: 1.5rem;
  width: 100%;
  padding: 0px 1.5rem;
  margin: 0.5rem 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.styles-module_notificationIndicator__3JOtX {
  line-height: 10px !important;
}