.subtitle{
   font-weight: 500 !important;
}


@media screen and (max-width: 800px) {

    .tabla2{
        display: none;
    }
}